/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* src/index.css */

/* Customize the NProgress bar color to match Tailwind's yellow-500 */
#nprogress .bar {
  background: #f59e0b; /* Tailwind CSS yellow-500 */
}

/* Customize the NProgress spinner (if enabled) */
#nprogress .spinner-icon {
  border-top-color: #f59e0b; /* Tailwind CSS yellow-500 */
  border-left-color: #f59e0b; /* Tailwind CSS yellow-500 */
}

/* Optional: Position the loading bar below the navbar */
#nprogress .bar {
  top: 64px; /* Adjust based on your navbar height */
}
